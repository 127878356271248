import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultFeedback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_feedback', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListFeedback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_feedback', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFeedback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_feedback', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFeedback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_feedback', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editFeedback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_feedback', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFeedback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_feedback', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    feedbackOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/feedback_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageFeedback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_feedback/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
