<template>
  <b-modal
    id="form-feedback"
    :visible="shallShowFeedbackFormModal"
    :title="`${$t('Form')} ${$t('Feedback')}`"
    footer-class="d-flex justify-content-between"
    size="lg"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-feedback-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeFeedbackForm)} ${$t('Feedback')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-feedback-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeFeedbackForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeFeedbackForm === 'Add' || typeFeedbackForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>

                  <!-- Field: Name -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeedbackForm === 'Add' || typeFeedbackForm === 'Edit')"
                      :label="$t('Name')"
                      label-for="feedback-name"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Name')"
                        rules="required"
                      >
                        <b-form-input
                          id="feedback-name"
                          v-model="feedbackData.name"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Name')"
                      label-for="feedback-name"
                    >
                      <span class="form-info-box">{{ feedbackData.name }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Status -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeedbackForm === 'Add' || typeFeedbackForm === 'Edit')"
                      :label="$t('Status')"
                      label-for="feedback-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Status')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="feedbackData.status"
                          name="feedback-status"
                          value="active"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Active') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="feedbackData.status"
                          name="feedback-status"
                          value="inactive"
                          class="custom-control-info cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Status')"
                      label-for="feedback-status"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          :variant="`light-${isStatusVariant(feedbackData.status)}`"
                          class="text-capitalize"
                        >
                          {{ $t(textFirstUpper(feedbackData.status)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Position -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeFeedbackForm === 'Add' || typeFeedbackForm === 'Edit')"
                      :label="$t('Position')"
                      label-for="feedback-position"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Position')"
                        rules="required"
                      >
                        <b-form-input
                          id="feedback-position"
                          v-model="feedbackData.position"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Position')"
                      label-for="feedback-position"
                    >
                      <span class="form-info-box">{{ feedbackData.position }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>
                  <!-- Field: Message -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeFeedbackForm === 'Add' || typeFeedbackForm === 'Edit')"
                      :label="$t('Message')"
                      label-for="feedback-message"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Message')"
                        rules="required"
                      >
                        <b-form-textarea
                          id="feedback-message"
                          v-model="feedbackData.message"
                          rows="3"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Message')"
                      label-for="feedback-message"
                    >
                      <span class="form-info-box">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="feedbackData.message" />
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>

                  <!-- Field: Image -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeedbackForm === 'Add' || typeFeedbackForm === 'Edit')"
                      :label="$t('Image')"
                      label-for="feedback-channel-id"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Image')"
                        :rules="(typeFeedbackForm === 'Add') ? 'required' : ''"
                      >
                        <div class="file-upload-form">
                          <b-form-file
                            v-model="feedbackData.image_file"
                            accept="image/*"
                            placeholder="เลือกไฟล์รูปภาพ..."
                            no-drop
                            @change="previewImage"
                          />
                        </div>
                        <div
                          v-if="feedbackData.image_url !== ''"
                          style="padding: 10px;"
                        >
                          <img
                            :src="feedbackData.image_url"
                            style="height: 120px;"
                          >
                        </div>
                        <div
                          v-if="feedbackData.image_data != ''"
                          style="padding: 10px;"
                        >
                          <img
                            :src="feedbackData.image_data"
                            style="height: 120px;"
                          >
                        </div>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Image')"
                      label-for="feedback-channel-id"
                    >
                      <span class="form-info-box">{{ feedbackData.image }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Sort -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeFeedbackForm === 'Add' || typeFeedbackForm === 'Edit')"
                      :label="$t('Sort')"
                      label-for="feedback-sort"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Sort')"
                        rules="required"
                      >
                        <b-row>
                          <b-col cols="3">
                            <b-form-radio
                              v-model="feedbackData.sort_type"
                              name="feedback-sort"
                              value="input"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Input') }}</span>
                            </b-form-radio>
                          </b-col>
                          <b-col cols="3">
                            <b-form-input
                              id="feedback-sort"
                              v-model="feedbackData.sort"
                              :state="getValidationState(validationContext)"
                              :disabled="!(feedbackData.sort_type === 'input')"
                            />
                          </b-col>
                          <b-col
                            v-if="typeFeedbackForm === 'Add'"
                            cols="3"
                          >
                            <b-form-radio
                              v-model="feedbackData.sort_type"
                              name="feedback-sort"
                              value="first"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('First') }}</span>
                            </b-form-radio>
                          </b-col>
                          <b-col
                            v-if="typeFeedbackForm === 'Add'"
                            cols="3"
                          >
                            <b-form-radio
                              v-model="feedbackData.sort_type"
                              name="feedback-sort"
                              value="last"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Last') }}</span>
                            </b-form-radio>
                          </b-col>
                        </b-row>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Sort')"
                      label-for="feedback-sort"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          :variant="`light-${isSortVariant(feedbackData.sort)}`"
                          class="text-capitalize"
                        >
                          {{ $t(textFirstUpper(feedbackData.sort)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BBadge,
  BFormFile,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import tableFeedback from './tableFeedback'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BBadge,
    BFormFile,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
  },
  model: {
    prop: 'shallShowFeedbackFormModal',
    event: 'update:shall-show-feedback-form-modal',
  },
  props: {
    shallShowFeedbackFormModal: {
      type: Boolean,
      required: true,
    },
    typeFeedbackForm: {
      type: String,
      default: 'Add',
    },
    feedbackData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeFeedbackForm === 'Add') {
        delete this.feedbackData.image_data
        delete this.feedbackData.image_url
        if (this.feedbackData.sort_type !== 'input') {
          this.feedbackData.sort = ''
        }
        store.dispatch('store-feedback/addFeedback', this.feedbackData)
          .then(response => {
            if (this.feedbackData.image_file !== null) {
              this.saveImage(response.data.id)
            } else {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Added'),
                text: this.$i18n.t('Your data has been added'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.$emit('feedback-form-update')
              this.$emit('update:shall-show-feedback-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeFeedbackForm === 'Edit') {
        delete this.feedbackData.image_data
        delete this.feedbackData.image_url
        store.dispatch('store-feedback/editFeedback', { id: this.feedbackData.id, data: this.feedbackData })
          .then(() => {
            if (this.feedbackData.image_file !== null) {
              this.updateImage(this.feedbackData.id)
            } else {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$emit('feedback-form-update')
              this.$emit('update:shall-show-feedback-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeFeedbackForm === 'Delete') {
        store.dispatch('store-feedback/deleteFeedback', {
          id: this.feedbackData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('feedback-form-update')
            this.$emit('update:shall-show-feedback-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    saveImage(id) {
      const formData = new FormData()
      formData.append('file', this.feedbackData.image_file)

      store.dispatch('store-feedback/uploadImageFeedback', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.feedbackData.image_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Added'),
            text: this.$i18n.t('Your data has been added'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('feedback-form-update')
          this.$emit('update:shall-show-feedback-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    updateImage(id) {
      const formData = new FormData()
      formData.append('file', this.feedbackData.image_file)

      store.dispatch('store-feedback/uploadImageFeedback', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.feedbackData.image_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Updated'),
            text: this.$i18n.t('Your data has been updated'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('feedback-form-update')
          this.$emit('update:shall-show-feedback-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    onDiscard() {
      this.$emit('discard-feedback-form')
      this.$emit('update:shall-show-feedback-form-modal', false)
    },
    previewImage(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.feedbackData.image_data = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
      isStatusToText,
    } = tableFeedback()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
      isStatusToText,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
